<template>
  <div>
    <v-dialog v-model="dialog.dialogProgress" persistent width="300" eager>
      <v-card color="primary" dark>
        <v-card-text>
          {{progressMessage}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
              outlined dense hide-details
            :label="$t('BranchTIPMaint.name')"
            v-model="vDataTable.editedItem.name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-textarea class="ma-0 pa-0" hide-details
            outlined
            name="input-7-4"
            :label="$t('BranchTIPMaint.street1')"
            v-model="vDataTable.editedItem.street_1"
            rows="4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
              outlined dense hide-details
            :label="$t('BranchTIPMaint.tel')"
            v-model="vDataTable.editedItem.tel"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-textarea class="ma-0 pa-0" hide-details
            outlined
            name="input-7-4"
            :label="$t('BranchTIPMaint.url')"
            v-model="vDataTable.editedItem.url"
            rows="4"
          ></v-textarea>
        </v-col>
        <v-col cols="2" class="mt-0 pa-0">
          <!-- <v-checkbox label="Foo" input-value="1"></v-checkbox> -->
          <v-checkbox outlined dense hide-details
            false-value="N"
            true-value="Y"
            ref="checkBoxIHCSpecialStaining"
            v-model="vDataTable.editedItem.active_flag">
            <template v-slot:label>
              <div><span class='text--primary'>Active</span></div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import Base64Binary from 'base64-arraybuffer'

/* import { consoleLog } from '../../../node_modules-/echarts/lib/util/log' */
export default {
  components: {
  },

  props: ['parentComponent'],

  data () {
    return {
      utils: require('@/utils'),
      stringFormat: require('string-format'),
      appName: process.env.VUE_APP_DB_APP_NAME,
      rules: {
        winName: value => {
          const pattern = /^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/
          return pattern.test(value) || 'Invalid Window Name'
        },
      },
      dialog: {
        dialogProgress: false,
        dialogAddEdit: false,
        dialogLanguage: false,
      },
      vDataTable: {
        search: '',
        selected: [],
        data: [],
        editedIndex: -1,
        editedItem: {
          win_name: '',
          window_comment: ''
        },
        defaultItem: {
          win_name: '',
          window_comment: ''
        },
        editedLanguageIndex: -1,
        editedLanguageItem: {
        },
        defaultLanguageItem: {
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
          // mustSort: true
        },
        expanded: [],
      },
      progressMessage: '',
      languages: [],
      languageEdit: true,
    }
  },

  computed: {
    ...mapGetters(['getShowAppBar', 'getAppBarHeight']),
    formTitle () {
      return this.vDataTable.editedIndex === -1 ? this.$t('BranchTIPMaint.newBranchTip') : this.$t('BranchTIPMaint.newBranchTip')
    },
    formLanguageTitle () {
      return this.vDataTable.editedLanguageIndex === -1 ? this.$t('BranchTIPMaint.newBranchTipLanguage') : this.$t('BranchTIPMaint.editBranchTipLanguage')
    },
    headers () {
      return [
        { text: '', align: 'center', value: 'data-table-select' },
        { text: 'skey', align: 'left', value: 'skey', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('actions'), value: 'actions', sortable: false, class: 'text-xl-left body-1 black--text' },
        { text: this.$t('BranchTIPMaint.name'), value: 'name', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('BranchTIPMaint.street1'), value: 'street_1', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('BranchTIPMaint.tel'), value: 'tel', align: 'left', class: 'text-xl-left body-1 black--text' },
        /* { text: this.$t('BranchTIPMaint.url'), value: 'url', align: 'left', class: 'text-xl-left body-1 black--text' }, */
        /* { text: this.$t('createUser'), value: 'create_user_id', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('createDate'), value: 'create_date', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('maintUser'), value: 'maint_user_id', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('maintDate'), value: 'maint_date', align: 'left', class: 'text-xl-left body-1 black--text' }, */
        { text: 'Active', value: 'active_flag', align: 'left', class: 'text-xl-left body-1 black--text' },
      ]
    },
    subHeaders () {
      return [
        { text: this.$t('navWindow.windowName'), value: 'win_name' },
        { text: this.$t('createUser'), value: 'create_user_id' },
      ]
    },
    subHeadersLanguage () {
      return [
        { text: '', align: 'center', value: 'data-table-select' },
        { text: this.$t('actions'), value: 'actions', sortable: false },
        { text: this.$t('language'), value: 'language_comment' },
        { text: this.$t('BranchTIPMaint.name'), value: 'name', align: 'left' },
        { text: this.$t('BranchTIPMaint.street1'), value: 'street_1', align: 'left' },
      ]
    },
    offsetAppBar () {
      // return this.getShowAppBar ? 0 : this.getAppBarHeight
      return this.getShowAppBar ? this.getAppBarHeight : 0
    },
  },

  watch: {
    'dialog.dialogAddEdit' (val) {
      val || this.close()
    }
  },

  async created () {
    this.retrieveLanguage()
    await this.retrieveBranchTip(this.appName)
  },

  mounted () {
  },

  updated () {
  },

  methods: {
    /* getIp () {
      console.log('getip')
      this.axios.post('https://api.db-ip.com/v2/free/self', {
      })
        .then(response => {
          console.log(response, 'response')
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    }, */
    selectLanguage (language) {
    },
    initialize () {
      this.vDataTable.selected = []
      this.vDataTable.editedIndex = -1
      this.vDataTable.editedItem = {
        win_name: '',
        window_comment: ''
      }
      this.vDataTable.defaultItem = {
        win_name: '',
        window_comment: ''
      }
    },
    newBranchTipLanguage (item) {
      this.vDataTable.editedItem = Object.assign({}, item)
      // this.dialogLanguage = true
      this.languageEdit = false
    },
    expandCollapseNode (expand) {
      if (expand) {
        this.vDataTable.expanded = this.vDataTable.data
      } else {
        this.vDataTable.expanded = []
      }
    },
    findCopyHeader (skey) {
      return this.vDataTable.data.find(function (x) {
        return (x.skey === skey)
      })
    },
    findLanguage (skey) {
      return this.vDataTable.data.find(function (x) {
        return (x.skey === skey)
      }).branch_tip_language
    },
    findNodeSelectedLanguage (skey) {
      return this.vDataTable.data.find(function (x) {
        return (x.skey === skey)
      }).selectedLanguage
    },
    retrieveLanguage () {
      this.axios.post(process.env.VUE_APP_API + '/branch_tip/retrieveLanguage' + process.env.VUE_APP_DATABASE_TYPE, {
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.languages = response.data.data
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async retrieveBranchTip (appName) {
      console.log('retrieveBranchTip')
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/branch_tip/retrieveBranchTip' + process.env.VUE_APP_DATABASE_TYPE, {
        app_name: appName
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.data = response.data.data
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    async addBranchTip (name, street1, url, tel, activeFlag) {
      if (!url) {
        url = ''
      }
      if (!tel) {
        tel = ''
      }
      await this.axios.post(process.env.VUE_APP_API + '/branch_tip/addBranchTip' + process.env.VUE_APP_DATABASE_TYPE, {
        name: name,
        street_1: street1,
        url: url,
        tel: tel,
        active_flag: activeFlag
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.editedItem.skey = response.data.data.skey
            this.vDataTable.data.push(this.vDataTable.editedItem)
            this.retrieveBranchTip()
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async updateBranchTip (skey, name, street1, url, tel, activeFlag) {
      if (!url) {
        url = ''
      }
      if (!tel) {
        tel = ''
      }
      console.log(street1, 'street1')
      console.log(url, 'url')
      console.log(tel, 'tel')
      await this.axios.post(process.env.VUE_APP_API + '/branch_tip/updateBranchTip' + process.env.VUE_APP_DATABASE_TYPE, {
        skey: skey,
        name: name,
        street_1: street1,
        url: url,
        tel: tel,
        active_flag: activeFlag,
      })
        .then(response => {
          if (response.data.isSuccess) {
            Object.assign(this.vDataTable.data[this.vDataTable.editedIndex], this.vDataTable.editedItem)
            this.retrieveBranchTip()
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async addBranchTipLanguage (skey, languageSkey, name, street1) {
      await this.axios.post(process.env.VUE_APP_API + '/branch_tip/addBranchTipLanguage' + process.env.VUE_APP_DATABASE_TYPE, {
        skey: skey,
        language_skey: languageSkey,
        name: name,
        street_1: street1,
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.editedLanguageItem.skey = response.data.data.skey
            this.vDataTable.editedLanguageItem.language_comment = this.languages.find(function (x) {
              return (x.language_skey === languageSkey)
            }).language_comment
            this.findLanguage(skey).push(this.vDataTable.editedLanguageItem)
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async updateBranchTipLanguage (skey, languageSkey, name, street1) {
      await this.axios.post(process.env.VUE_APP_API + '/branch_tip/updateBranchTipLanguage' + process.env.VUE_APP_DATABASE_TYPE, {
        skey: skey,
        language_skey: languageSkey,
        name: name,
        street_1: street1,
      })
        .then(response => {
          if (response.data.isSuccess) {
            Object.assign(this.findLanguage(skey)[this.vDataTable.editedLanguageIndex], this.vDataTable.editedLanguageItem)
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    checkDeleteselected () {
      if (this.vDataTable.selected.length > 0) {
        this.$swal({
          text: this.$t('BranchTIPMaint.confirmDeleteSelected'),
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no')
        }).then((result) => {
          if (result) {
            this.deleteSelected()
          }
        })
      } else {
        this.$swal({type: 'warning', title: this.$t('BranchTIPMaint.messageDeleteNotSelected')})
      }
    },
    checkDeleteselectedLanguage (item) {
      if (this.findNodeSelectedLanguage(item.skey).length > 0) {
        this.$swal({
          text: this.$t('BranchTIPMaint.confirmBranchTipLanguageDeleteSelected'),
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no')
        }).then((result) => {
          if (result) {
            this.deleteLanguageSelected(item)
          }
        })
      } else {
        this.$swal({type: 'warning', title: this.$t('BranchTIPMaint.messagedeleteBranchTipLanguageNotSelected')})
      }
    },
    async deleteSelected () {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')

      var del = []

      await this.utils.asyncForEach(this.vDataTable.selected, async function (x) {
        this.progressMessage = this.stringFormat(this.$t('BranchTIPMaint.progressMessageDelete'), x.name_name)
        var val = await this.deleteBranchTip(x)

        if (val !== undefined) {
          del.push(val)
        }
      }.bind(this))
      for (const x in del) {
        this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(x), 1)
      }

      this.dialog.dialogProgress = false
    },
    async deleteLanguageSelected (item) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')

      var del = []

      await this.utils.asyncForEach(this.findNodeSelectedLanguage(item.skey), async function (x) {
        this.progressMessage = this.stringFormat(this.$t('BranchTIPMaint.progressMessageNodeLanguageDelete'), x.name)
        var val = await this.deleteLanguage(x)

        if (val !== undefined) {
          del.push(val)
        }
      }.bind(this))
      for (const x in del) {
        var selectedLanguage = this.findNodeSelectedLanguage(item.skey)
        selectedLanguage.splice(selectedLanguage.indexOf(x), 1)
      }

      this.dialog.dialogProgress = false
    },
    async deleteBranchTip (item) {
      try {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')
        var skey = item.skey
        var valRet
        await this.axios.post(process.env.VUE_APP_API + '/branch_tip/deleteBranchTip' + process.env.VUE_APP_DATABASE_TYPE, {
          skey: skey
        })
          .then(async response => {
            if (response.data.isSuccess) {
              if (this.vDataTable.data.indexOf(item) >= 0) {
                this.vDataTable.data.splice(this.vDataTable.data.indexOf(item), 1)
              }
              this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
              valRet = item
            } else {
              await this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(async e => {
            await this.$swal({type: 'error', title: e.message})
          })
        return valRet
      } catch (e) {
        await this.$swal({type: 'error', title: e.message})
      } finally {
        this.dialog.dialogProgress = false
      }
    },
    newItem () {
      this.vDataTable.editedItem.active_flag = 'Y'
    },
    editItem (item) {
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.dialog.dialogAddEdit = true
    },
    editLanguageItem (item) {
      this.languageEdit = true
      this.vDataTable.editedLanguageIndex = this.findLanguage(item.skey).indexOf(item)
      this.vDataTable.editedLanguageItem = Object.assign({}, item)
      this.dialog.dialogLanguage = true
    },
    async deleteItemConfirm (item) {
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.$swal({
        text: this.stringFormat(this.$t('BranchTIPMaint.confirmDelete'), item.name),
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then(async (result) => {
        if (result) {
          var val = await this.deleteBranchTip(item)
          if (val !== undefined) {
            if (this.vDataTable.selected.indexOf(this.vDataTable.editedItem) >= 0) {
              this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(this.vDataTable.editedItem), 1)
            }
          }
          this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
          this.vDataTable.editedIndex = -1
        }
      })
    },
    async deleteLanguage (item) {
      try {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')
        var skey = item.skey
        var languageSkey = item.language_skey
        var valRet
        await this.axios.post(process.env.VUE_APP_API + '/branch_tip/deleteBranchTipLanguage' + process.env.VUE_APP_DATABASE_TYPE, {
          skey: skey,
          language_skey: languageSkey
        })
          .then(async response => {
            if (response.data.isSuccess) {
              if (this.findLanguage(item.skey).indexOf(item) >= 0) {
                this.findLanguage(item.skey).splice(this.findLanguage(item.skey).indexOf(item), 1)
              }
              this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
              valRet = item
            } else {
              await this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(async e => {
            await this.$swal({type: 'error', title: e.message})
          })
        return valRet
      } catch (e) {
        await this.$swal({type: 'error', title: e.message})
      } finally {
        this.dialog.dialogProgress = false
      }
    },
    async deleteLanguageConfirm (item) {
      this.vDataTable.editedLanguageIndex = this.findLanguage(item.skey).indexOf(item)
      this.vDataTable.editedLanguageItem = Object.assign({}, item)
      this.$swal({
        text: this.stringFormat('Are you sure delete language {} node {}?', item.language_comment, item.name),
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then(async (result) => {
        if (result) {
          var val = await this.deleteLanguage(item)
          if (val !== undefined) {
            var selectedLanguage = this.findNodeSelectedLanguage(item.skey)
            if (selectedLanguage.indexOf(item) >= 0) {
              selectedLanguage.splice(selectedLanguage.indexOf(item), 1)
            }
          }
          this.vDataTable.editedLanguageItem = Object.assign({}, this.vDataTable.defaultItem)
          this.vDataTable.editedLanguageIndex = -1
        }
      })
    },
    close () {
      this.dialog.dialogAddEdit = false
      this.$nextTick(() => {
        this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
        this.vDataTable.editedIndex = -1
      })
    },
    async save () {
      if (this.vDataTable.editedIndex > -1) {
        await this.updateBranchTip(this.vDataTable.editedItem.skey,
          this.vDataTable.editedItem.name,
          this.vDataTable.editedItem.street_1,
          this.vDataTable.editedItem.url,
          this.vDataTable.editedItem.tel,
          this.vDataTable.editedItem.active_flag)
      } else {
        await this.addBranchTip(this.vDataTable.editedItem.name,
          this.vDataTable.editedItem.street_1,
          this.vDataTable.editedItem.url,
          this.vDataTable.editedItem.tel,
          this.vDataTable.editedItem.active_flag)
      }
      this.close()
    },
    closeLanguage () {
      this.dialog.dialogLanguage = false
      this.$nextTick(() => {
        this.vDataTable.editedLanguageItem = Object.assign({}, this.vDataTable.defaultLanguageItem)
        this.vDataTable.editedLanguageIndex = -1
      })
    },
    async saveLanguage () {
      if (this.vDataTable.editedLanguageIndex > -1) {
        await this.updateBranchTipLanguage(this.vDataTable.editedLanguageItem.skey,
          this.vDataTable.editedLanguageItem.language_skey,
          this.vDataTable.editedLanguageItem.name,
          this.vDataTable.editedLanguageItem.street_1)
      } else {
        await this.addBranchTipLanguage(this.vDataTable.editedItem.skey,
          this.vDataTable.editedLanguageItem.language_skey,
          this.vDataTable.editedLanguageItem.name,
          this.vDataTable.editedLanguageItem.street_1)
      }
      this.closeLanguage()
    },
  },
}
</script>
<style scoped>
.style-1 {
  color: rgb(215, 44, 44);
}
.style-2 {
  color: rgb(114,114,67);
}
.sticky-table-footer td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  border-top: thin solid rgba(0,0,0,.12);
}
</style>